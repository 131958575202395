<template>
  <div :class="isActive==true? 'AnswerContainer active':'AnswerContainer'" ref="AnswerContainer">
    <div
      class="loadingContainer"
      :style="dataLoaded == false ? 'display:flex' : 'display:none'"
    >
      <div class="loader"></div>
      <div class="loadingText">Loading Answers...</div>
    </div>
    <div class="AnswerNumber">{{this.InstanceData.answerTitle==null?"Answer":this.InstanceData.answerTitle}} {{ AnswerNumber }}</div>
    <div class="AnswerText">{{ AnswerText }}</div>
    <div v-for="(option, index) in options" :key="index" :class="option.isCorrect?'AnswerOption correctOption':'AnswerOption incorrectOption'">
      <fa-icon class="correctIcon"
              :icon="['fas', 'check']"
              v-if="option.isCorrect"
            ></fa-icon>
        <fa-icon class="correctIcon"
              :icon="['fas', 'times']"
              v-if="!option.isCorrect"
            ></fa-icon>
      <div class="AnsweroptionText">{{ option.text }}</div>
    </div>
        <div
      class="closeButton"
      @click="closeResult"
    >
      Close
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    InstanceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataLoaded: false,
      reponseUploading: false,
      AnswerNumber: 1,
      AnswerText: "",
      options: [],
      isActive:false
    };
  },
  methods: {
    setActive(){
      this.dataLoaded = false;
          fetch(this.initialPreferences.pollServerAnswerResultSender + this.InstanceData.questionId)
      .then((response) => response.json())
      .then((data) => {
        this.AnswerNumber=data.question.substr(0,
          data.question.indexOf(" "),
        ).match(/\d+/)[0];
        this.AnswerText = data.question.substr(
          data.question.indexOf(" ") + 1,
          data.question.length
        );
        this.options = data.Options.map((option) => ({
          text: option.name,
          isCorrect:option.isCorrect,
        }));
        this.dataLoaded = true;
      });
      this.isActive=true;            
      if(this.initialPreferences.googleAnalyticsTrackingCode!=null){
      this.$gtag.event('AssessmentAnswer', {
            'data':new Date(),
            'event_category': 'Initialisation',
            'project_primarytitle':this.initialPreferences.PrimaryProjectTitle,
            'project_secondarytitle':this.initialPreferences.SecondaryProjectTitle,
          'device':this.initialPreferences.deviceDetails,

            'value': 'Showing'
        });
      }

    },
    setInActive(){
      this.isActive=false;
    },
    closeResult(){
      this.$emit("setChildInactive",this);
    }
  },
};
</script>

<style>
</style>